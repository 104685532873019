import React from 'react'
import Head from 'next/head'
import Link from 'next/link'

const Dashboard404 = () => {
  return (
    <>
      <Head>
        <title>AUB Paymaate: Page not found</title>
      </Head>
      <div className="flex items-center flex-col justify-center bg-surface h-screen w-screen">
        <div className="flex text mb-5 text-gray-500">
          <svg className="w-20 h-20" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clipRule="evenodd" /></svg>
        </div>
        <span className="inline text-gray-500 text-xl leading-7 font-normal whitespace-normal">
          Sorry, but the page you were looking for could not be found.
        </span>
        <div className="mt-2">
          <Link href="/">
            <a className="no-underline">
              <div className="inline-flex items-center flex-row">
                <div className="flex text-magpie-400 mr-1">
                  <svg className="w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M9.707 16.707a1 1 0 01-1.414 0l-6-6a1 1 0 010-1.414l6-6a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l4.293 4.293a1 1 0 010 1.414z" clipRule="evenodd" /></svg>
                </div>
                <span className="inline text-sm text-magpie-500 font-medium leading-5 whitespace-nowrap">
                  Return to the Dashboard homepage
                </span>
              </div>
            </a>
          </Link>
        </div>
      </div>
    </>
  )
}

export default Dashboard404
